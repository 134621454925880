export const environment = {
  production: true,
  isLocalEnv: false,
  firebase: {
    apiKey: 'AIzaSyDNfWEVkCpsmUv7j0tEylH4lIyYbzcmttA',
    authDomain: 'meander-pulse.firebaseapp.com',
    projectId: 'meander-pulse',
    storageBucket: 'meander-pulse.appspot.com',
    messagingSenderId: '28261644507',
    appId: '1:28261644507:web:9fd9c9e1e7326b36dcf4fe',
    measurementId: 'G-7ZY6ZZ985Z'
  },
  appVersion: '1.0.0',
  whereUserIs: 'https://www.travelpayouts.com/whereami',
  appCheckSiteKey: '6Les72okAAAAADUIBlppzJf5i2SUAbDuAef5Ak0k',
  FareUpThereAPI: 'https://api.meander.travel/v1/',
  disposableEmail: 'https://open.kickbox.com/v1/disposable/',
  branch_io: 'key_live_jyjgxGMJvjyOUl1QesXqGohfyrdiRlIB',
  web_survey_url: 'https://surveys.meander.travel/',
  stripePublicKey: 'pk_live_io5tz7mOskle0teTDxUmuiVK00Sk5UO0ik'
};
